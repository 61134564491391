<template>
  <div>
    <PageHeader>
      <div class="text-gray-900 uppercase text-2xl font-semibold py-2">
        Rapports des chiffres d'affaire
      </div>
    </PageHeader>
    <div class="p-6">
      <div class="flex justify-end items-center">
        <el-select
            v-model="year"
            class="mr-2"
            placeholder="Select"
            @change="GET_FETCH_RAPPORT"
        >
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>




          <el-button class="ml-2" @click="exportRapportRevenueExcel" :loading="exportLoading">
            <i class="el-icon-download"></i> Exporter en excel

          </el-button>

      </div>
      <div v-loading="listLoading">

        <div class="p-4 my-5 bg-white shadow">


          <el-table :data="items" v-loading="listLoading" style="width: 100%">
            <el-table-column label="MOIS" min-width="200">
              <template slot-scope="{ row }">
                <span class="text-blue">{{ row.name }}</span>
              </template>
            </el-table-column>

            <el-table-column
                prop="purchaseorder_date"
                label="C.A PREVISIONNEL "
                align="right"

            >
              <template slot-scope="{ row }">
                <span>{{ row.total_revenue | moneyFilter }} {{user.currency_code}}</span>
              </template>
            </el-table-column>
            <el-table-column label="TAUX DE MARGE" align="center" width="200">
              <template slot-scope="{ row }">
                <span class="">{{ row.marge }} </span>
              </template>
            </el-table-column>
            <el-table-column align="right" label="MARGE BRUTE" width="170">
              <template slot-scope="{ row }">
                <span class="">{{ row.total_profit | moneyFilter }} {{user.currency_code}} </span>
              </template>
            </el-table-column>
          </el-table>


        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { toThousandFilter } from "@/Filters";


import {
  parseDate,
  getCurrentYear,
} from "@/utils";
import { fetchRapportRevenue } from "@/api/sales2";
import { exportExcelRapportRevenue } from "@/api/export";

import PageHeader from "@/components/PageHeader";
import {mapGetters} from "vuex";


export default {
  name: "Rapport-Revenue",

  components: {PageHeader },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  computed:{
    ...mapGetters(["user"]),
  },
  data() {
    return {
      exportLoading: false,
      options: [
        {
          label: "2019",
          value: "2019",
        },
        {
          label: "2022",
          value: "2022",
        },
        {
          label: "2023",
          value: "2023",
        },
        {
          label: "2024",
          value: "2024",
        },
        {
          label: "2025",
          value: "2025",
        },
        {
          label: "2026",
          value: "2026",
        },
        {
          label: "2027",
          value: "2027",
        },
        {
          label: "2028",
          value: "2028",
        },
      ],

      listLoading: false,
      items: [],
      year: getCurrentYear(),
    };
  },

  mounted() {
    this.GET_FETCH_RAPPORT();
  },
  methods: {

    async GET_FETCH_RAPPORT() {
      this.listLoading = true;
      await fetchRapportRevenue(this.year).then((res) => {
        setTimeout(() => {
          this.items = res.data;
          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },


    exportRapportRevenueExcel() {
      this.exportLoading = true;

      exportExcelRapportRevenue(this.year)
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download", this.year+ "rapport_ca.xls"
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.exportLoading = false;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
</script>

<style lang="scss" scoped></style>
